var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column", attrs: { id: "main-container" } }, [
    _c("div", { staticClass: "columns" }, [_c("app-organization-table")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }