<template>
  <aside id="navMenu" class="column is-hidden-touch is-2-widescreen aside hero">
    <div class="main">
      <router-link :to="{ name: 'dashboard' }" class="item is-size-7-tablet animate-left-side" active-class="menu-item--selected" exact>
        <span class="icon">
          <i class="fas fa-home"></i>
        </span>
        <span class="name">{{ $t('dashboard.title') }}</span>
      </router-link>
      <router-link :to="{ name: 'organization' }" class="item is-size-7-tablet animate-left-side" active-class="menu-item--selected" exact>
        <span class="icon">
          <i class="fas fa-sitemap"></i>
        </span>
        <span class="name">{{ $t('organizations.title') }}</span>
      </router-link>
      <router-link :to="{ name: 'user' }" class="item is-size-7-tablet animate-left-side" active-class="menu-item--selected" exact>
        <span class="icon">
          <i class="fas fa-users"></i>
        </span>
        <span class="name">{{ $t('users.title') }}</span>
      </router-link>
      <router-link :to="{ name: 'seats' }" class="item is-size-7-tablet animate-left-side" active-class="menu-item--selected" exact>
        <span class="icon">
          <i class="fas fa-align-justify"></i>
        </span>
        <span class="name">{{ $t('seats.title') }}</span>
      </router-link>
      <template v-if="isAdminUser">
        <router-link :to="{ name: 'pricing' }" class="item is-size-7-tablet animate-left-side" active-class="menu-item--selected" exact>
          <span class="icon">
            <i class="fas fa-dollar-sign"></i>
          </span>
          <span class="name">Pricing</span>
        </router-link>
      </template>
      <router-link :to="{ name: 'self-serve' }" class="item is-size-7-tablet animate-left-side" active-class="menu-item--selected" exact>
        <span class="icon">
          <i class="fas fa-tasks"></i>
        </span>
        <span class="name">Self Serve</span>
      </router-link>
      <template v-if="hasPermission('exchange:view')">
        <router-link
          :to="{
            name: 'exchangeRate',
            query: currentQuery
          }"
          class="item is-size-7-tablet animate-left-side"
          active-class="menu-item--selected"
          exact
        >
          <span class="icon">
            <i class="fa fa-money-bill-transfer"></i>
          </span>
          <span class="name">Exchange Rate</span>
        </router-link>
      </template>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'

import utils from '@/utils/user-utils'
export default {
  name: 'asideMenu',
  computed: {
    ...mapGetters(['loggedUser']),
    isAdminUser() {
      return utils.isAdminUser(parseInt(this.loggedUser.id))
    },
    currentQuery() {
      return { ...this.$route.query }
    }
  },
  methods: {
    hasPermission(permissionKey) {
      let permission = utils.hasPermission(permissionKey)
      if (!permission) {
        this.$router.push({ name: 'dashboard' })
      }
      return !!permission
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../assets/sass/colors';

.aside {
  display: block;
  background-color: $light-grey-2;
  border-right: 1px solid $grey;
  min-height: 100%;
}

.aside .main {
  padding: 30px;
  color: $grey;
}

.aside .title {
  color: $grey;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.aside .main .item {
  display: flex;
  padding: 0.5rem;
}

.menu-item--selected {
  background-color: $light-grey;
  margin: 0 -40px;
  border-left: 0.3rem solid $accent;
  transition: border-left 0.1s ease-in-out;

  span:first-child {
    margin-left: 40px;
  }
}

.aside .main .icon {
  font-size: 19px;
  padding-right: 30px;
  color: $dark-grey-2;
}

.aside .main .name {
  font-size: 15px;
  color: $dark-grey-2;
  font-weight: 500;
}

.action-buttons .control.is-grouped {
  display: inline-block;
  margin-right: 30px;
}

.action-buttons .control.is-grouped:last-child {
  margin-right: 0;
}

.action-buttons .control.is-grouped .button:first-child {
  border-radius: 5px 0 0 5px;
}

.action-buttons .control.is-grouped .button:last-child {
  border-radius: 0 5px 5px 0;
}

.action-buttons .control.is-grouped .button {
  margin-right: -5px;
  border-radius: 0;
}

.animate-left-side:not(.menu-item--selected) {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 -40px;
  padding-left: 40px;
  position: relative;
  overflow: hidden;

  .icon {
    padding-left: 40px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 50%;
    bottom: 50%;
    background: $grey;
    width: 0.3rem;
    -webkit-transition-property: bottom top;
    transition-property: bottom top;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  &:hover {
    background-color: $light-grey;
  }

  &:hover:before {
    bottom: 0;
    top: 0;
  }
}

a {
  outline: none; // For avoid dotted borders on firefox
}
</style>
