<template>
  <nav class="navbar is-primary has-shadow">
    <div class="container">
      <div class="navbar-brand">
        <a class="navbar-item is-size-4" @click="goToDashboard"> rely <span class="has-text-weight-light">admin</span> </a>
        <div :class="{ 'is-active': showNav }" class="navbar-burger burger" data-target="toolbarMenu" @click="showNav = !showNav">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div id="toolbarMenu" :class="{ 'is-active': showNav }" class="navbar-menu">
        <div class="navbar-start is-hidden-desktop">
          <router-link :to="{ name: 'dashboard' }" tag="div" class="navbar-item">
            {{ $t('dashboard.title') }}
          </router-link>
          <router-link :to="{ name: 'organization' }" tag="div" class="navbar-item">
            {{ $t('organizations.title') }}
          </router-link>
          <router-link :to="{ name: 'user' }" tag="div" class="navbar-item">
            {{ $t('users.title') }}
          </router-link>
          <hr />
        </div>
        <div class="navbar-end">
          <div id="profileMenu" :class="{ 'is-active': showMenuDropdown }" class="navbar-item has-dropdown">
            <a v-click-outside="hideProfileDropdown" class="navbar-link" @click="showProfileDropdown"> {{ loggedUser.username }} </a>
            <div class="navbar-dropdown is-boxed">
              <a class="navbar-item" @click="logOut()">{{ $t('dashboard.menu.logout') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ClickOutside } from '@/directives/click-outside'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'toolbar',
  directives: {
    ClickOutside
  },
  data() {
    return {
      // Mock purpose
      user: { username: 'lorem user' },
      showMenuDropdown: false,
      showNav: false
    }
  },
  computed: {
    ...mapGetters(['loggedUser'])
  },
  created() {
    this.getLoggedUser().then((exists) => {
      if (!exists) {
        this.$router.push({ name: 'login' })
      }
    })
  },
  methods: {
    ...mapActions(['signOut', 'getLoggedUser']),
    goToDashboard() {
      this.$router.push({ name: 'dashboard' })
    },
    logOut() {
      try {
        this.signOut()
        this.$router.push({ name: 'login' })
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(error)
        }
      }
    },
    showProfileDropdown() {
      this.showMenuDropdown = true
    },
    hideProfileDropdown() {
      this.showMenuDropdown = false
    }
  }
}
</script>

<style lang="scss" scoped>
.has-shadow {
  box-shadow: -4px 6px 19px $grey;
}
</style>
