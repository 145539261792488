var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    {
      staticClass: "column is-hidden-touch is-2-widescreen aside hero",
      attrs: { id: "navMenu" }
    },
    [
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "router-link",
            {
              staticClass: "item is-size-7-tablet animate-left-side",
              attrs: {
                to: { name: "dashboard" },
                "active-class": "menu-item--selected",
                exact: ""
              }
            },
            [
              _c("span", { staticClass: "icon" }, [
                _c("i", { staticClass: "fas fa-home" })
              ]),
              _c("span", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.$t("dashboard.title")))
              ])
            ]
          ),
          _c(
            "router-link",
            {
              staticClass: "item is-size-7-tablet animate-left-side",
              attrs: {
                to: { name: "organization" },
                "active-class": "menu-item--selected",
                exact: ""
              }
            },
            [
              _c("span", { staticClass: "icon" }, [
                _c("i", { staticClass: "fas fa-sitemap" })
              ]),
              _c("span", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.$t("organizations.title")))
              ])
            ]
          ),
          _c(
            "router-link",
            {
              staticClass: "item is-size-7-tablet animate-left-side",
              attrs: {
                to: { name: "user" },
                "active-class": "menu-item--selected",
                exact: ""
              }
            },
            [
              _c("span", { staticClass: "icon" }, [
                _c("i", { staticClass: "fas fa-users" })
              ]),
              _c("span", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.$t("users.title")))
              ])
            ]
          ),
          _c(
            "router-link",
            {
              staticClass: "item is-size-7-tablet animate-left-side",
              attrs: {
                to: { name: "seats" },
                "active-class": "menu-item--selected",
                exact: ""
              }
            },
            [
              _c("span", { staticClass: "icon" }, [
                _c("i", { staticClass: "fas fa-align-justify" })
              ]),
              _c("span", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.$t("seats.title")))
              ])
            ]
          ),
          _vm.isAdminUser
            ? [
                _c(
                  "router-link",
                  {
                    staticClass: "item is-size-7-tablet animate-left-side",
                    attrs: {
                      to: { name: "pricing" },
                      "active-class": "menu-item--selected",
                      exact: ""
                    }
                  },
                  [
                    _c("span", { staticClass: "icon" }, [
                      _c("i", { staticClass: "fas fa-dollar-sign" })
                    ]),
                    _c("span", { staticClass: "name" }, [_vm._v("Pricing")])
                  ]
                )
              ]
            : _vm._e(),
          _c(
            "router-link",
            {
              staticClass: "item is-size-7-tablet animate-left-side",
              attrs: {
                to: { name: "self-serve" },
                "active-class": "menu-item--selected",
                exact: ""
              }
            },
            [
              _c("span", { staticClass: "icon" }, [
                _c("i", { staticClass: "fas fa-tasks" })
              ]),
              _c("span", { staticClass: "name" }, [_vm._v("Self Serve")])
            ]
          ),
          _vm.hasPermission("exchange:view")
            ? [
                _c(
                  "router-link",
                  {
                    staticClass: "item is-size-7-tablet animate-left-side",
                    attrs: {
                      to: {
                        name: "exchangeRate",
                        query: _vm.currentQuery
                      },
                      "active-class": "menu-item--selected",
                      exact: ""
                    }
                  },
                  [
                    _c("span", { staticClass: "icon" }, [
                      _c("i", { staticClass: "fa fa-money-bill-transfer" })
                    ]),
                    _c("span", { staticClass: "name" }, [
                      _vm._v("Exchange Rate")
                    ])
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }