var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("app-header"),
      _c(
        "div",
        { staticClass: "columns is-marginless" },
        [_c("app-aside"), _c("router-view")],
        1
      ),
      _c("app-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }