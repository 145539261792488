var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar is-primary has-shadow" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "navbar-brand" }, [
        _c(
          "a",
          {
            staticClass: "navbar-item is-size-4",
            on: { click: _vm.goToDashboard }
          },
          [
            _vm._v(" rely "),
            _c("span", { staticClass: "has-text-weight-light" }, [
              _vm._v("admin")
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "navbar-burger burger",
            class: { "is-active": _vm.showNav },
            attrs: { "data-target": "toolbarMenu" },
            on: {
              click: function($event) {
                _vm.showNav = !_vm.showNav
              }
            }
          },
          [_c("span"), _c("span"), _c("span")]
        )
      ]),
      _c(
        "div",
        {
          staticClass: "navbar-menu",
          class: { "is-active": _vm.showNav },
          attrs: { id: "toolbarMenu" }
        },
        [
          _c(
            "div",
            { staticClass: "navbar-start is-hidden-desktop" },
            [
              _c(
                "router-link",
                {
                  staticClass: "navbar-item",
                  attrs: { to: { name: "dashboard" }, tag: "div" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("dashboard.title")) +
                      "\n        "
                  )
                ]
              ),
              _c(
                "router-link",
                {
                  staticClass: "navbar-item",
                  attrs: { to: { name: "organization" }, tag: "div" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("organizations.title")) +
                      "\n        "
                  )
                ]
              ),
              _c(
                "router-link",
                {
                  staticClass: "navbar-item",
                  attrs: { to: { name: "user" }, tag: "div" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("users.title")) +
                      "\n        "
                  )
                ]
              ),
              _c("hr")
            ],
            1
          ),
          _c("div", { staticClass: "navbar-end" }, [
            _c(
              "div",
              {
                staticClass: "navbar-item has-dropdown",
                class: { "is-active": _vm.showMenuDropdown },
                attrs: { id: "profileMenu" }
              },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.hideProfileDropdown,
                        expression: "hideProfileDropdown"
                      }
                    ],
                    staticClass: "navbar-link",
                    on: { click: _vm.showProfileDropdown }
                  },
                  [_vm._v(" " + _vm._s(_vm.loggedUser.username) + " ")]
                ),
                _c("div", { staticClass: "navbar-dropdown is-boxed" }, [
                  _c(
                    "a",
                    {
                      staticClass: "navbar-item",
                      on: {
                        click: function($event) {
                          return _vm.logOut()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("dashboard.menu.logout")))]
                  )
                ])
              ]
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }