<template>
  <section>
    <app-header></app-header>
    <div class="columns is-marginless">
      <app-aside></app-aside>
      <!-- <transition name="slide" mode="out-in"> -->
      <router-view />
      <!-- </transition> -->
    </div>
    <app-footer></app-footer>
  </section>
</template>

<script>
// @ is an alias to /src
// import Component from '@/folder/Component.vue'
import Header from './Header'
import Aside from './Aside'
import Footer from './Footer'

export default {
  name: 'dashboard',
  components: {
    'app-header': Header,
    'app-aside': Aside,
    'app-footer': Footer
  },
  data() {
    return {
      user: {}
    }
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
section {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  div {
    flex: 1;
  }
}
</style>
