/**
 * @type Object
 * @description Global directive to bind event for listening outside click
 */

import Vue from 'vue'

export const ClickOutside = {
  bind(el, binding, vnode) {
    el.event = event => {
      if (!(el === event.target)) {
        // console.log(binding.expression)
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.event)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.event)
  }
}

Vue.directive('click-outside', ClickOutside)
