<template>
  <footer class="footer has-border--top">
    <div class="level">
      <div class="level-left">
        <div class="level-item"></div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="content has-text-right">
            <p class="is-size-7 has-text-grey has-text-weight-semibold">
              <a class="has-text-grey"> Copyright &copy; 2018 </a>
            </p>
          </div>
        </div>
        <!-- <div class="level-item is-hidden-mobile">
          <span class="has-text-grey">|</span>
        </div>
        <div class="level-item">
          <div class="content has-text-right">
            <p class="is-size-7  has-text-weight-semibold">
              <router-link :to="{name: 'terms'}" class="has-text-grey"> {{ $t('info.terms') }} </router-link>
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'bottom',
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">

  .footer {
    padding: .6rem .5rem .6rem;
    background: white;
  }

  .has-border--top {
    border-top: 1px solid $grey;
  }

</style>
