<template>
  <div id="main-container" class="column">
    <div class="columns">
      <app-organization-table></app-organization-table>
    </div>
  </div>
</template>

<script>
import OrganizationTable from '@/components/organization/OrganizationTable'

export default {
  name: 'dashboardStart',
  components: {
    'app-organization-table': OrganizationTable
  },
  data() {
    return {}
  },
  created() {
    if (process.env.NODE_ENV === 'development') {
      console.log('the enviroment is', process.env.NODE_ENV)
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
